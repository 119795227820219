<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-block">
        <b-card-title class="mb-1">
          {{ chartTitle }}
        </b-card-title>
        <b-card-sub-title class="font-small-2 font-italic">
          {{ chartSubTitle }}
        </b-card-sub-title>
      </div>
      <div class="text-muted font-small-2">
        {{ chartPrefixText }}
      </div>
      <div class="width-200">
        <v-select
          v-if="selectOptions"
          v-model="selectValue"
          :options="selectOptions.dataList"
          placeholder="Seçiniz"
          :clearable="false"
          @input="getData"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <pre-loading
        v-if="loading"
        loading-text="Yükleniyor..."
        color="text-secondary bg-light-primary mx-2 mb-2 rounded p-2"
      />
      <div v-else>
        <vue-apex-charts
          v-if="barChart.series.length > 0"
          type="bar"
          :height="heightSize"
          :options="barChart.chartOptions"
          :series="barChart.series"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  components: {
    PreLoading,
    VueApexCharts,
    vSelect,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    chartTitle: {
      type: String,
      default: '',
    },
    chartSubTitle: {
      type: String,
      default: '',
    },
    chartPrefixText: {
      type: String,
      default: '',
    },
    heightSize: {
      type: Number,
      default: 450,
    },
    seriesData: {
      type: Array,
      required: true,
    },
    categoriesData: {
      type: Array,
      required: true,
    },
    colorsData: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    selectOptions: {
      type: Object,
      default: null,
    },
    getData: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      selectValue: null,
      barChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'center',
            floating: false,
            itemMargin: {
              horizontal: 5,
              vertical: 5,
            },
          },
          colors: [],
          dataLabels: {
            enabled: false,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}₺`
            },
          },
          tooltip: {
            y: {
              formatter(val) {
                return `${new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2 }).format(val)}₺`
              },
            },
          },
          yaxis: {
            title: {
              text: '₺',
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  watch: {
    seriesData: {
      deep: true,
      handler(val) {
        this.barChart.series = val
      },
    },
    categoriesData: {
      deep: true,
      handler(val) {
        this.barChart.chartOptions.labels = val
        this.barChart.chartOptions = {
          ...this.barChart.chartOptions,
        }
      },
    },
    colorsData: {
      deep: true,
      handler(val) {
        this.barChart.chartOptions.colors = val
        this.barChart.chartOptions = {
          ...this.barChart.chartOptions,
        }
      },
    },
    selectOptions: {
      deep: true,
      handler(val) {
        if (!this.selectValue) {
          this.selectValue = val.selected
        }
      },
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      this.barChart.series = this.seriesData
      this.barChart.chartOptions.labels = this.categoriesData
      this.barChart.chartOptions.colors = this.colorsData
      this.barChart.chartOptions = {
        ...this.barChart.chartOptions,
      }
    },
  },
}
</script>
