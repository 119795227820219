<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-block">
        <b-card-title class="mb-1">
          Son Tahsilatlar
        </b-card-title>
        <b-card-sub-title class="font-small-2 font-weight-light font-italic">
          Gerçekleşen son 10 tahsilat işlemi
        </b-card-sub-title>
      </div>
      <div>
        <b-button
          variant="flat-secondary"
          size="sm"
          pill
          to="/payment-history"
        >
          Tümünü Göster
        </b-button>
      </div>
    </b-card-header>
    <pre-loading
      v-if="loading"
      loading-text="Yükleniyor..."
      color="text-secondary bg-light-primary mx-2 mb-2 rounded p-2"
    />
    <b-table
      v-else
      responsive="sm"
      class="mb-0"
      :fields="fields"
      :items="reportData.dataList.latestPayments"
      empty-text="Kayıt bulunamadı."
      show-empty
      bordered
      hover
      striped
    >
      <template #cell(company)="data">
        <b-link :to="'/payment-history/view/' + data.item.id ">
          <div class="font-weight-bold text-danger">
            {{ data.item.company }}
          </div>
          <div class="font-small-2 text-secondary">
            {{ moment(data.item.created).format('llll') }}
          </div>
        </b-link>
      </template>
      <template #cell(amount)="data">
        <div class="font-weight-bold">
          {{ data.item.amount | toCurrency }} ₺
        </div>
        <div class="text-muted font-small-2">
          {{ data.item.instalment_text }}
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard, BCardSubTitle, BCardTitle, BCardHeader, BTable, BButton, BLink,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'LatestPayments',
  components: {
    PreLoading,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BCard,
    BTable,
    BButton,
    BLink,
  },
  data() {
    return {
      fields: [
        {
          key: 'company',
          label: 'MÜŞTERİ',
          thClass: 'text-nowrap',
        },
        {
          key: 'amount',
          label: 'Toplam Tahsilat',
          thClass: 'width-200 text-right text-nowrap',
          tdClass: 'width-200 text-right text-nowrap',
        },
      ],
    }
  },
  computed: {
    reportParams() {
      return this.$store.getters['reportUsers/reportParams']
    },
    loading() {
      return this.$store.getters['reportUsers/loading']
    },
    reportData() {
      return this.$store.getters['reportUsers/reportData']
    },
  },
}
</script>
