<template>
  <div>
    <user-search :get-report="getReport" />
    <template v-if="reportParams.id_users">
      <report-params :get-report="getReport" />
      <counters />
      <b-row>
        <b-col cols="12">
          <apex-column-chart
            chart-title="Yıllık Ödeme Grafiği"
            chart-sub-title="Alt Başlık"
            :loading="loading"
            :colors-data="['#447c00','#bd2525']"
            :categories-data="reportData.charts.yearly.categories"
            :series-data="reportData.charts.yearly.series"
            :select-options="reportData.params.years"
            :get-data="changeYear"
          />
        </b-col>
        <b-col cols="12">
          <latest-payments />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <late-payments />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <pending-payments />
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import UserSearch from '@/views/Reports/Users/components/UserSearch.vue'
import ReportParams from '@/views/Reports/Users/components/ReportParams.vue'
import Counters from '@/views/Reports/Users/components/Counters.vue'
import LatestPayments from '@/views/Reports/Users/components/LatestPayments.vue'
import LatePayments from '@/views/Reports/Users/components/LatePayments.vue'
import PendingPayments from '@/views/Reports/Users/components/PendingPayments.vue'
import ApexColumnChart from '@/views/Reports/components/ApexColumnChart.vue'

export default {
  name: 'UserReportIndex',
  components: {
    BRow,
    BCol,
    UserSearch,
    ReportParams,
    Counters,
    LatestPayments,
    LatePayments,
    PendingPayments,
    ApexColumnChart,
  },
  computed: {
    reportParams() {
      return this.$store.getters['reportUsers/reportParams']
    },
    reportData() {
      return this.$store.getters['reportUsers/reportData']
    },
    loading() {
      return this.$store.getters['reportUsers/loading']
    },
  },
  methods: {
    getReport() {
      this.$store.dispatch('reportUsers/getData', this.reportParams)
    },
    changeYear(year) {
      this.reportParams.year = year
      this.getReport()
    },
  },
}
</script>
