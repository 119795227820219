<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-block">
        <b-card-title class="mb-1">
          Vadesi Geçmişler
        </b-card-title>
        <b-card-sub-title class="font-small-2 font-weight-light font-italic">
          Ödeme vadesi geçmiş 10 tahsilat işlemi
        </b-card-sub-title>
      </div>
      <div>
        <b-button
          variant="flat-secondary"
          size="sm"
          pill
          to="/payment-collections"
        >
          Tümünü Göster
        </b-button>
      </div>
    </b-card-header>
    <pre-loading
      v-if="loading"
      loading-text="Yükleniyor..."
      color="text-secondary bg-light-primary mx-2 mb-2 rounded p-2"
    />
    <b-table
      v-else
      responsive="sm"
      class="mb-0"
      :fields="fields"
      :items="reportData.dataList.latePayments"
      empty-text="Kayıt bulunamadı."
      show-empty
      borderless
      hover
      striped
    >
      <template #cell(customer)="data">
        <div class="font-small-2 text-muted">
          {{ data.item.title }}
        </div>
        <div class="font-weight-bold text-danger">
          {{ data.item.customer }}
        </div>
      </template>
      <template #cell(pdate)="data">
        <div class="text-secondary font-weight-bold">
          {{ moment(data.item.pdate).format('DD.MM.YYYY') }}
        </div>
        <div>
          <template v-if="moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days') === 0">
            <small class="text-muted">Bugün</small>
          </template>
          <template v-else>
            <small class="text-warning">{{ Math.abs(moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days')) }} gün önce.</small>
          </template>
        </div>
      </template>
      <template #cell(amount)="data">
        <div v-if="data.item.partial_amount && data.item.amount !== data.item.partial_amount">
          <del>
            <div class="text-muted">
              {{ data.item.amount | toCurrency }} TL
            </div>
          </del>
          <div class="font-weight-bold">
            {{ data.item.amount - data.item.partial_amount | toCurrency }} TL
          </div>
          <div>
            <small class="text-success">{{ data.item.partial_amount | toCurrency }} TL Ödendi</small>
          </div>
        </div>
        <div
          v-else
          class="font-weight-bold"
        >
          {{ data.item.amount | toCurrency }} TL
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard, BCardSubTitle, BCardTitle, BCardHeader, BTable, BButton,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'LatestPayments',
  components: {
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BCard,
    BTable,
    BButton,
    PreLoading,
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ',
          thClass: 'text-nowrap',
        },
        {
          key: 'pdate',
          label: 'VADE GÜNÜ',
          thClass: 'width-200 text-center text-nowrap',
          tdClass: 'width-200 text-center text-nowrap',
        },
        {
          key: 'amount',
          label: 'Toplam Tahsilat',
          thClass: 'width-200 text-right text-nowrap',
          tdClass: 'width-200 text-right text-nowrap',
        },
      ],
    }
  },
  computed: {
    reportParams() {
      return this.$store.getters['reportUsers/reportParams']
    },
    loading() {
      return this.$store.getters['reportUsers/loading']
    },
    reportData() {
      return this.$store.getters['reportUsers/reportData']
    },
  },
}
</script>
